<template>
  <div>
    <strong>{{ getProducertName() }}</strong>
  </div>
</template>

<script>
import get from "lodash/get";
export default {
  name: "UserProductTableVariantQuantity",
  data() {
    return {
      data: {},
    };
  },
  methods: {
    getProducertName() {
      return get(this.data, `ppl.productproducer.producer.role.name`);
    },
  },
};
</script>
