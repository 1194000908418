var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('router-link',{attrs:{"to":{
      name: 'UserMarketProduct',
      params: {
        familyId: _vm.familyId,
        marketId: _vm.marketId,
        productId: _vm.productId,
      },
    }}},[_c('strong',[_vm._v(_vm._s(_vm.getProductName()))])]),_c('router-link',{attrs:{"to":{
      name: 'UserMarketProduct',
      params: {
        familyId: _vm.familyId,
        marketId: _vm.marketId,
        productId: _vm.productId,
      },
    }}},[_c('div',[_vm._v(_vm._s(_vm.getVariantName()))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }