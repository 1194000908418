<template>
  <div>
    <router-link
      :to="{
        name: 'UserMarketProduct',
        params: {
          familyId: familyId,
          marketId: marketId,
          productId: productId,
        },
      }"
    >
      <strong>{{ getProductName() }}</strong>
    </router-link>
    <router-link
      :to="{
        name: 'UserMarketProduct',
        params: {
          familyId: familyId,
          marketId: marketId,
          productId: productId,
        },
      }"
    >
      <div>{{ getVariantName() }}</div>
    </router-link>
  </div>
</template>

<script>
import get from "lodash/get";
import { getTranslation } from "../../../helpers/common";
export default {
  name: "MarketCartTableVariantTitle",

  props: {
    marketId: { type: Number },
  },

  data() {
    return {
      data: {},
    };
  },
  computed: {
    lang: function () {
      return this.$i18n.locale;
    },
    familyId() {
      return this.$store.state.connections.current.family.id;
    },
    productId() {
      return get(this.data, "view.mp.ppl.productproducer.id");
    },
  },
  methods: {
    getProductName() {
      const translations = get(
        this.data,
        "view.mp.ppl.productproducer.translations"
      );
      return getTranslation(translations, this.lang);
    },
    getVariantName() {
      const translations = get(this.data, "view.mp.ppl.translations");
      return getTranslation(translations, this.lang);
    },
  },
};
</script>
