<template>
  <figure class="table-figure">
    <img class="border rounded" :src="getImage()" height="50" />
  </figure>
</template>

<script>
import get from "lodash/get";
export default {
  name: "MarketProductTableVariantImage",

  props: {
    mainImg: { type: String, default: "" },
    altImg: { type: String, default: "" },
  },

  methods: {
    getImage() {
      const variantImage = get(this.data, `ppl.image.image_thumb`);
      if (variantImage) {
        return variantImage;
      }
      const productImage = get(
        this.data,
        `ppl.productproducer.image.image_thumb`
      );
      if (productImage) {
        return productImage;
      }

      return "https://picsum.photos/36";
    },
  },
};
</script>
