<template>
  <strong>{{ getSender() }}</strong>
</template>

<script>
import get from "lodash/get";
export default {
  name: "InvoicesTableSender",
  methods: {
    getSender() {
      return get(this.data, "i_from.name");
    },
  },
};
</script>
