<template>
  <div :class="{ opacity50: disabled }">
    <div v-if="hasIncident()" class="ui-type-heading text-right strikethrough">
      {{ getPriceOrig() }}
    </div>
    <div
      class="ui-type-heading text-right"
      :class="{ strikethrough: strikeThrough }"
    >
      {{ getPrice() }}
    </div>
  </div>
</template>

<script>
import get from "lodash/get";
import { mapGetters } from "vuex";
import { formatPrice } from "../../../helpers/common";
import { ORDER_STATUS } from "../../../config/global";

export default {
  name: "MarketOrderTableVariantTotal",

  computed: {
    disabled() {
      return this.data.status === ORDER_STATUS.CANCELED;
    },

    strikeThrough() {
      return this.data.status === ORDER_STATUS.CANCELED;
    },

    ...mapGetters("connections", ["currencySymbol"]),
  },

  methods: {
    getPrice() {
      const price = get(this.data, "price", null);
      return price !== null
        ? formatPrice(price, this.currencySymbol).format()
        : "";
    },

    hasIncident() {
      return get(this.data, "incident", null) !== null;
    },

    getPriceOrig() {
      const price = get(this.data, "incident.detail.orig_price", null);
      return price !== null
        ? formatPrice(price, this.currencySymbol).format()
        : "";
    },
  },
};
</script>
