<template>
  <div :class="{ opacity50: disabled }">
    <figure class="table-figure">
      <img class="border rounded" :src="getImage()" height="50" />
    </figure>
  </div>
</template>

<script>
import get from "lodash/get";
import { ORDER_STATUS } from "../../../config/global";
export default {
  name: "MarketOrderTableVariantImage",

  computed: {
    disabled() {
      return this.data.status === ORDER_STATUS.CANCELED;
    },
  },

  methods: {
    getImage() {
      const image = get(this.data, `ppm.img.image_thumb`);
      if (image) {
        return image;
      }
      return "https://picsum.photos/36";
    },
  },
};
</script>
