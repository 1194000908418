<template>
  <div>
    <div class="ui-type-caption text-gray-600">
      {{ toUpper(parentCategory()) }}
    </div>
    <div>{{ category() }}</div>
  </div>
</template>

<script>
import get from "lodash/get";
import toUpper from "lodash/toUpper";
import { getTranslation } from "../../../helpers/common";
export default {
  name: "UserProductTableVariantCategory",

  computed: {
    lang: function () {
      return this.$i18n.locale;
    },
  },

  methods: {
    toUpper: toUpper,
    parentCategory() {
      return getTranslation(
        get(this.data, "ppl.productproducer.category.parent.translations", {}),
        this.lang
      );
    },
    category() {
      return getTranslation(
        get(this.data, "ppl.productproducer.category.translations", {}),
        this.lang
      );
    },
  },
};
</script>
