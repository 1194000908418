<template>
  <CModal
    size="xl"
    :title="title"
    :show="show"
    :closeOnBackdrop="true"
    :centered="true"
    @update:show="onClose"
  >
    <div style="height: 500px; width: 100%">
      <l-map
        :zoom="zoom"
        :center="center"
        :options="mapOptions"
        :bounds="bounds"
        v-if="center"
      >
        <l-tile-layer :url="url" :attribution="attribution" />
        <l-marker
          v-for="marker in markers"
          :key="marker.id"
          :lat-lng="marker.latLng"
        >
          <l-popup>
            <div>
              {{ marker.name }} <br />
              {{ markerInfo(marker) }}
            </div>
          </l-popup>
        </l-marker>
      </l-map>
    </div>
    <template #footer>
      <CButton color="primary" variant="outline" @click="onClose"
        >Chiudi</CButton
      >
    </template>
  </CModal>
</template>

<script>
import get from "lodash/get";
import { latLng, latLngBounds } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import { ApiCall, GetDataManagerNew, Query } from "../../../ds";

export default {
  name: "MarketDeliveryMapModal",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },

  props: {
    market: { type: Object, required: true },
    familyId: { type: Number, required: true },
    title: { type: String, default: "" },
    show: { type: Boolean, default: false },
  },

  data() {
    const center = this.getMapCenter(this.market);
    return {
      zoom: 13,
      center,
      mapOptions: { zoomSnap: 0.5 },
      markers: [],
      bounds: null,

      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>',
    };
  },

  computed: {},
  mounted() {
    this.loadMarkers();
  },
  methods: {
    getMapCenter(market) {
      const address = get(market, "role.family.addresses[0]", {});
      const { latitude, longitude } = address;
      if (latitude && longitude) {
        return latLng(latitude, longitude);
      }
      return null;
    },

    loadMarkers() {
      const self = this;
      const { familyId, market } = this;
      const dm = GetDataManagerNew("family_market_deliveries", [
        familyId,
        market.id,
      ]);
      const q = new Query();
      ApiCall(dm, q, (response) => {
        self.markers = response.result
          .filter((item) => {
            return get(item, "role.family.addresses[0]", null) !== null;
          })
          .map((item) => {
            const name = get(item, "role.family.name", "");
            const firstAddress = get(item, "role.family.addresses[0]", {});
            const {
              id,
              address,
              postal_code,
              city,
              country,
              latitude,
              longitude,
            } = firstAddress;
            return {
              id,
              name,
              address,
              postal_code,
              city,
              country,
              latitude,
              longitude,
              latLng: latLng(latitude, longitude),
            };
          });
        self.bounds = latLngBounds(
          self.markers.map((item) => [item.latitude, item.longitude])
        );
      });
    },

    markerInfo(marker) {
      const { address, postal_code, city, country } = marker;
      return `${address} ${postal_code}, ${city} (${country})`;
    },

    onClose() {
      this.$emit("update:show", false);
    },
  },
};
</script>
