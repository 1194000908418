<template>
  <span>{{ getInvoiceDate() }}</span>
</template>

<script>
import get from "lodash/get";
import { formatDate } from "../../../helpers/common";
export default {
  name: "MarketInvoicesTableDate",

  methods: {
    getInvoiceDate() {
      const date = get(this.data, "data_emitted");
      return date ? formatDate(new Date(date), "dd-MM-yyyy") : "";
    },
  },
};
</script>
