<template>
  <div class="badge" :class="color">{{ label }}</div>
</template>

<script>
import orderMixin from "../../../mixins/order";

export default {
  name: "MarketOrderStatusBadge",

  mixins: [orderMixin],

  props: {
    order: { type: Object, required: true },
  },

  computed: {
    label() {
      const status = this.orderStatus(this.order);
      return status.label;
    },

    color() {
      const status = this.orderStatus(this.order);
      return `badge-${status.color}`;
    },
  },
};
</script>
